@media only screen and (max-width: 760px) {
  .grlaeb {
    padding-top: 28vh !important;
  }
}

@media only screen and (max-height: 760px) {
  .grlaeb {
    padding-top: 25vh !important;
  }
}

.grlaeb {
  padding-top: 20vh;
  overflow-x: hidden !important;
  transition: all 0.2s ease-out !important;
  -webkit-transition: all 0.2s ease-out !important;
}

.default-message {
  width: 87vw;
  font-family: Inconsolata, monospace;
  font-size: 2.5vh;
  font-weight: bold;
  z-index: 100;
  position: absolute;
  left: 25px;
  top: 80px;
  color: white;
}

body {
  height: 100vh;
  background-color: #282936;
}

.actions {
  left: 25px;
  top: 20px;
  position: absolute;
  display: flex;
  flex-direction: row;
  z-index: 100;
}

#border {
  position: absolute;
  width: 100%;
  border-bottom: 2px solid #1b1f23;
  z-index: 100;
  top: 55px;
}

.circle {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

.red {
  background: red;
}

.orange {
  background: orange;
}

.green {
  background: green;
}
